import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';

import AuthService from '../../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));

export const register = createAsyncThunk('auth/register', async (data, thunkAPI) => {
    try {
        const response = await AuthService.register(data);
        thunkAPI.dispatch(setMessage(response.data.message));
        return response.data;
    } catch (error) {
        const message = error?.response?.data?.message || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const login = createAsyncThunk('auth/login', async ({ userName, password }, thunkAPI) => {
    try {
        const data = await AuthService.login(userName, password);
        const message = data?.message;
        return { user: data };
    } catch (error) {
        const message = error?.response?.data?.message || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
    }
});

export const logout = createAsyncThunk('auth/logout', async () => {
    await AuthService.logout();
});

const initialState = user ? { isLoggedIn: true, user, isLoading: false } : { isLoggedIn: false, user: null, isLoading: false };

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.isLoading = false;
        },
        [register.pending]: (state, action) => {
            state.isLoggedIn = false;
            state.isLoading = true;
        },
        [register.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.isLoading = false;
        },
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.isLoading = false;
            state.user = action.payload.user;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.isLoading = false;
            state.user = null;
        },
        [login.pending]: (state, action) => {
            state.isLoggedIn = false;
            state.isLoading = true;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        }
    }
});

const { reducer } = authSlice;
export default reducer;
